import { GoogleGenerativeAI } from "@google/generative-ai";
import {
    geminiPromptMessage,
    graderMessage,
    personalProCollection,
} from "../constants/message";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

const geminiPrompt = geminiPromptMessage;

export async function gradeResumeWithGoogleAI(resumeData) {
    const aiResponse = await geminiGrades(resumeData);
    const grades = await localEvaluator(aiResponse, resumeData);
    return grades;
}

async function geminiGrades(resumeData) {
    try {
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

        const resumeFormatedData = formatResume(resumeData);
        const prompt = geminiPrompt + JSON.stringify(resumeFormatedData);
        
        const result = await model.generateContent(prompt);
        const response = result.response;
        const text = response.text();

        // Extract JSON from the response
        const jsonMatch = text.match(/\{[\s\S]*\}/);
        if (!jsonMatch) {
            throw new Error("No valid JSON found in response");
        }

        const cleanedJson = jsonMatch[0]
            .replace(/[\u0000-\u001F\u007F-\u009F]/g, "")
            .replace(/\n/g, " ")
            .replace(/,\s*([\]}])/g, "$1")
            .replace(/\\(['"])/g, "$1")
            .trim();

        const JSONResponse = JSON.parse(cleanedJson);
        JSONResponse.score.total = 0;
        return JSONResponse;
    } catch (error) {
        console.error("Error in resume grading:", error);
        return {
            score: {
                total: 0,
                breakdown: {
                    grammarAndSpellcheck: 0,
                    consistentVerbTense: 0,
                    contentParameters: 0,
                    actionVerbEffectiveness: 0,
                    actionVerbFrequency: 0,
                    technicalSkills: 0,
                    eduSectionPoints: 0
                },
            },
            criticalIssues: ["An error occurred during the grading process."],
            grammarCheck: {
                status: "error",
                issues: ["Unable to perform grammar check."],
            },
            bulletPointAnalysis: {
                strongBullets: [],
                improvementNeeded: [],
            },
            improvements: [],
            completedSections: [],
            sectionErrors: []
        };
    }
}

function formatResume(resumeData){

    const copy = structuredClone(resumeData);
    copy.skills = "";
    if(copy.skills_visibility === "" && copy.skillsVisibility.language === "yes"){
        copy.skills += resumeData.skills.language;
    }
    if(copy.skills_visibility === "" && copy.skillsVisibility.technical === "yes"){
        copy.skills += resumeData.skills.technical;
    }

    return copy;
}

function localEvaluator(geminiResponse, resumeData) {
    console.log(geminiResponse);
    try {
        // adding new array to store issues from sections and formatting
        // console.log(resumeData);
        // geminiResponse.sectionErrors = [];

        // Evaluate bullet points
        const bulletPtsEval = processAndEvaluate(resumeData);
        geminiResponse.score.breakdown.lengthCompliance =
            bulletPtsEval.lengthCompliance;
        geminiResponse.score.breakdown.quantifiableAchievements =
            bulletPtsEval.quantifiableAchievements;

        if (
            bulletPtsEval.feedback !== null
        ) {
            geminiResponse.bulletPointAnalysis.improvementNeeded.push(
                bulletPtsEval.feedback
            );
        }

        // Evaluate LinkedIn URL
        const linkedinEvaluation = evaluateLinkedInURL(
            resumeData.personal_information.linkedin
        );
        geminiResponse.score.breakdown.linkedinURLScore = linkedinEvaluation.score;
        if (linkedinEvaluation.score < 3) {
            geminiResponse.sectionErrors.push({
                title: "LinkedIn URL",
                comments: [linkedinEvaluation.feedback],
            });
        }

        // Validate reverse chronological order of sections
        const dates = extractDatesForChronologicalEvaluation(resumeData);
        // console.log(dates);
        const reverseChronology = evaluateReverseChronologyWithFeedback(dates);
        geminiResponse.score.breakdown.sectionChronology =
            reverseChronology.totalScore;
        if (reverseChronology.totalScore < 5) {
            geminiResponse.sectionErrors.push({
                title: "Section Chronology",
                comments: [reverseChronology.feedback],
            });
        }

        // Evaluate page numbers
        const pageNumbersScore = evaluatePageNumbers(resumeData);
        geminiResponse.score.breakdown.pageNumbers = pageNumbersScore;
        if (pageNumbersScore < 2) {
            geminiResponse.sectionErrors.push({
                title: "Page Numbers",
                comments: ["Ensure your resume is no longer than 2 pages."],
            });
        }

        const orderScore = checkSectionsOrder(resumeData);
        geminiResponse.score.breakdown.orderScore = orderScore.score;
        if (orderScore.feedback.length > 0) {
            geminiResponse.sectionErrors.push({
                title: "Order of sections",
                comments: orderScore.feedback,
            });
        }

        const fullStopPoints = checkFullStopsInBulletPoints(resumeData);
        geminiResponse.score.breakdown.fullStopCheck = fullStopPoints;
        if (fullStopPoints < 1) {
            geminiResponse.grammarCheck.status = "needs improvement";
            geminiResponse.grammarCheck.issues.push(
                graderMessage.GRAMMER.ERROR.PUNCTUATION
            );
        }

        const proNounResult = scorePersonalPronouns(resumeData);
        // console.log(proNounResult.score);
        geminiResponse.score.breakdown.proNounResult = proNounResult.score;
        if (proNounResult.score < 2) {
            geminiResponse.grammarCheck.status = "needs improvement";
            geminiResponse.grammarCheck.issues.push(proNounResult.message);
        }

        const achievementScore = evaluateAchievementsAndCertifications(resumeData);
        geminiResponse.score.breakdown.achievementScore = achievementScore.score;
        if (achievementScore.score < 2) {
            geminiResponse.sectionErrors.push({
                title: "Achievements and Certifications Section",
                comments: achievementScore.feedback,
            });
        }

        const leadershipScore = evaluateLeadership(resumeData);
        geminiResponse.score.breakdown.leadershipScore = leadershipScore.score;
        if (leadershipScore.score < 2) {
            geminiResponse.sectionErrors.push({
                title: "Leadership Section",
                comments: leadershipScore.feedback,
            });
        }

        const eduScore = evaluateEduSection(resumeData);
        geminiResponse.score.breakdown.eduSectionPoints += eduScore.score;
        if (geminiResponse.score.breakdown.eduSectionPoints === 2) {
            geminiResponse.sectionErrors = geminiResponse.sectionErrors.filter(item => item.title !== "Education Section");
        }
        else if (eduScore.feedback.length > 0) {
            const eduComment = geminiResponse.sectionErrors.find(item => item.title === "Education Section");
            if (!eduComment) {
                eduComment.comments.push(eduScore.feedback);
            }
        }

        geminiResponse.score.total = calculateBreakdownSum(geminiResponse.score);
        geminiResponse.sectionErrors = geminiResponse.sectionErrors.filter(section =>
            !(section.comments.length === 0)
        );

    
        // Evaluate technical skills and handle feedback
        const technicalSkillsResult = evaluateTechnicalSkills(resumeData);
        
        // Important: First remove ALL mentions of technical skills feedback 
        // from both criticalIssues and sectionErrors
        if (geminiResponse.criticalIssues) {
            geminiResponse.criticalIssues = geminiResponse.criticalIssues.filter(
                issue => !issue.toLowerCase().includes('technical skill')
            );
        }
        
        geminiResponse.sectionErrors = geminiResponse.sectionErrors.filter(
            section => section.title !== "Technical Skills" && 
                      !section.comments.some(comment => 
                          comment.toLowerCase().includes('technical skill')
                      )
        );

        // Update score
        geminiResponse.score.total += technicalSkillsResult.score;
        geminiResponse.score.breakdown.technicalSkills = technicalSkillsResult.score;

        // Check if we have enough diverse technical skills
        const skills = resumeData.skills.technical.split(',').map(s => s.trim());
        const hasEnoughSkills = skills.length >= 10;
        const skillCategories = {
            programming: ['python', 'java', 'javascript', 'c++', 'sql', 'r'],
            data_tools: ['tableau', 'power bi', 'excel'],
            methodologies: ['agile', 'scrum', 'lean six sigma'],
            other_tools: ['erp', 'microsoft', 'gantt']
        };

        let categoriesCovered = 0;
        for (const [_, keywords] of Object.entries(skillCategories)) {
            if (keywords.some(skill => 
                skills.some(s => s.toLowerCase().includes(skill.toLowerCase()))
            )) {
                categoriesCovered++;
            }
        }

        // If we have both enough skills and good category coverage,
        // ensure there's no negative feedback
        if (hasEnoughSkills && categoriesCovered >= 3) {
            // Add positive feedback to section check
            geminiResponse.sectionErrors = geminiResponse.sectionErrors.filter(
                section => section.title !== "Technical Skills"
            );
            
            if (!geminiResponse.completedSections) {
                geminiResponse.completedSections = [];
            }
            
            geminiResponse.completedSections.push({
                title: "Technical Skills",
                message: "Strong technical skills section with diverse skill set across multiple categories."
            });
        } else if (technicalSkillsResult.feedback.length > 0) {
            geminiResponse.sectionErrors.push({
                title: "Technical Skills",
                comments: technicalSkillsResult.feedback
            });
        }
         // Evaluate education section
        const educationResult = evaluateEducation(resumeData);
        geminiResponse.score.total += educationResult.score;
        geminiResponse.score.breakdown.eduSectionPoints = educationResult.score;

        // Clear any existing education feedback from AI
        geminiResponse.sectionErrors = geminiResponse.sectionErrors.filter(
            section => section.title !== "Education Section"
        );

        // Only add our local evaluation if there are issues
        if (educationResult.feedback.length > 0) {
            geminiResponse.sectionErrors.push({
                title: "Education Section",
                comments: educationResult.feedback
            });
        }

           // Clear any existing bullet formatting feedback from AI
        if (geminiResponse.grammarCheck && geminiResponse.grammarCheck.issues) {
            geminiResponse.grammarCheck.issues = geminiResponse.grammarCheck.issues.filter(
                issue => !issue.toLowerCase().includes('bullet point')
            );
        }

        // Check bullet point formatting
        const bulletFormatResult = checkBulletPointFormatting(resumeData);
        if (!bulletFormatResult.isConsistent) {
            if (!geminiResponse.grammarCheck) {
                geminiResponse.grammarCheck = {
                    status: "needs improvement",
                    issues: []
                };
            }
            geminiResponse.grammarCheck.status = "needs improvement";
            if (bulletFormatResult.feedback) {
                geminiResponse.grammarCheck.issues.push(bulletFormatResult.feedback);
            }
        }

 
         // Filter out empty comments at the end
         geminiResponse.sectionErrors = geminiResponse.sectionErrors.filter(section =>
             section.comments && section.comments.length > 0
         );

          // Cap the total score at 50
        geminiResponse.score.total = Math.min(geminiResponse.score.total, 50);

        // Format all score breakdowns to ensure they don't exceed their maximums
        const maxScores = {
            grammarAndSpellcheck: 3,
            consistentVerbTense: 3,
            fullStopCheck: 1,
            actionVerbFrequency: 2,
            proNounResult: 2,
            
            lengthCompliance: 4,
            quantifiableAchievements: 5,
            actionVerbEffectiveness: 5,
            contentParameters: 4,
            
            sectionChronology: 5,
            pageNumbers: 2,
            orderScore: 2,
            
            linkedinURLScore: 3,
            eduSectionPoints: 3,
            achievementScore: 2,
            technicalSkills: 3,
            leadershipScore: 2
        };

        // Cap each individual score
        Object.keys(maxScores).forEach(key => {
            if (geminiResponse.score.breakdown[key] !== undefined) {
                geminiResponse.score.breakdown[key] = Math.min(
                    geminiResponse.score.breakdown[key],
                    maxScores[key]
                );
            }
        });
 
         return geminiResponse;
    } catch (error) {
        console.error("Error in resume grading:", error);
        return {
            score: {
                total: 0,
                breakdown: {
                    grammarAndSpellcheck: 0,
                    consistentVerbTense: 0,
                    fullStopCheck: 0,
                    actionVerbFrequency: 0,
                    proNounResult: 0,

                    lengthCompliance: 0,
                    quantifiableAchievements: 0,
                    actionVerbEffectiveness: 0,
                    contentParameters: 0,

                    sectionChronology: 0,
                    pageNumbers: 0,
                    orderScore: 0,

                    linkedinURLScore: 0,
                    eduSectionPoints: 0,
                    achievementScore: 0,
                    technicalSkills: 0,
                    leadershipScore: 0,
                },
            },
            criticalIssues: ["An error occurred during the grading process."],
            grammarCheck: {
                status: "error",
                issues: ["Unable to perform grammar check."],
            },
            bulletPointAnalysis: {
                strongBullets: [],
                improvementNeeded: [],
            },
            sectionErrors: [
                {
                    title: "error",
                    comments: ["Unable to perform Resume check."],
                },
            ],
            improvements: [],
            completedSections: [],
        };
    }
}

function calculateBreakdownSum(score) {
    const breakdown = score.breakdown;
    let sum = 0;

    for (const key in breakdown) {
        if (breakdown.hasOwnProperty(key)) {
            sum += breakdown[key];
        }
    }

    return sum;
}

function evaluateEduSection(resumeData) {
    let score = 0;
    let feedback = [];

    if (resumeData.education && resumeData.education.length > 0) {
        const education = resumeData.education[0];
        const hasProperFormatting = education.university && education.graduation && education.location;

        if (hasProperFormatting) {
            score += 1;
        } else {
            feedback.push("The education section is missing a proper formatting eg: Grad Year, University, Location.");
        }
    } else {
        feedback.push("The education section is missing.");
    }

    return { score, feedback };
}
//     return { score, feedback };
// }
function checkBulletPointFormatting(resumeData) {
    // Function to extract exact bullet point character
    const getBulletChar = (text) => {
        // Remove spaces and get first character
        const cleanText = text.trim();
        return cleanText.charAt(0);
    };

    let allBullets = [];

    // Collect all bullets
    if (resumeData.experience) {
        resumeData.experience.forEach(exp => {
            if (exp.description) {
                allBullets = allBullets.concat(exp.description.map(desc => ({
                    section: 'experience',
                    text: desc
                })));
            }
        });
    }

    if (resumeData.leadership) {
        resumeData.leadership.forEach(lead => {
            if (lead.accomplishment) {
                allBullets = allBullets.concat(lead.accomplishment.map(acc => ({
                    section: 'leadership',
                    text: acc
                })));
            }
        });
    }

    // Since we use a resume builder, we know all bullets should start with exactly '•'
    const correctBullet = '•';
    let isConsistent = true;
    
    // Check each bullet point
    for (const bullet of allBullets) {
        const bulletChar = getBulletChar(bullet.text);
        if (bulletChar !== correctBullet) {
            isConsistent = false;
            break;
        }
    }

    return {
        isConsistent: true, // Force true since we use a resume builder
        feedback: null // No feedback needed since our builder ensures consistency
    };
}

// In localEvaluator function, add this before any bullet point checks
function removeBulletPointFeedback(response) {
    // Remove from critical issues
    if (response.criticalIssues) {
        response.criticalIssues = response.criticalIssues.filter(issue => 
            !issue.toLowerCase().includes('bullet') && 
            !issue.toLowerCase().includes('•')
        );
    }

    // Remove from grammar check
    if (response.grammarCheck && response.grammarCheck.issues) {
        response.grammarCheck.issues = response.grammarCheck.issues.filter(issue => 
            !issue.toLowerCase().includes('bullet') && 
            !issue.toLowerCase().includes('•')
        );
    }

    // Remove from section errors
    if (response.sectionErrors) {
        response.sectionErrors = response.sectionErrors.filter(section => 
            !section.title.toLowerCase().includes('bullet') &&
            !section.comments.some(comment => 
                comment.toLowerCase().includes('bullet') || 
                comment.toLowerCase().includes('•')
            )
        );
    }

    return response;
}


function evaluateAchievementsAndCertifications(resumeData) {
    let score = 0;
    let feedback = [];

    const hasCertifications = resumeData.certifications && resumeData.certifications.length > 0;
    const hasAwards = resumeData.awards && resumeData.awards.length > 0;

    if (hasCertifications && hasAwards) {
        score = 2;
    } else if (hasCertifications || hasAwards) {
        score = 1;
        if (hasCertifications) {
            feedback.push("Award section is not present.");
        } else {
            feedback.push("Certification section is not present.");
        }
    } else {
        feedback.push("Certifications and Award are missing or poorly formatted.");
    }

    return { score, feedback };
}

function evaluateLeadership(resumeData) {
    let score = 0;
    let feedback = [];

    const hasLeadership = resumeData.leadership && resumeData.leadership.length > 0;

    if (hasLeadership) {
        score = 2;
    }
    else {
        feedback.push("Leadership sections are missing.");
    }

    return { score, feedback };
}

function checkSectionsOrder(resumeData) {
    let score = 0;
    let feedback = [];
    if (resumeData.sectionOrder[0] == "education") score += 1;
    else {
        feedback.push(graderMessage.GRAMMER.ERROR.EDUORDER);
    }
    let indexOfExp = resumeData.sectionOrder.indexOf("experience");
    let indexOfLead = resumeData.sectionOrder.indexOf("leadership");
    if (indexOfLead != -1) {
        if (indexOfExp < indexOfLead) {
            score += 1;
        } else {
            feedback.push(graderMessage.GRAMMER.ERROR.LEADORDER);
        }
    } else {
        score += 1;
    }
    return { score, feedback };
}

function checkFullStopsInBulletPoints(resumeData) {
    let allBullets = [];

    if (resumeData.experience && resumeData.experience.length > 0) {
        resumeData.experience.forEach((item) => {
            allBullets = allBullets.concat(item.description);
        });
    }

    if (resumeData.leadership && resumeData.leadership.length > 0) {
        resumeData.leadership.forEach((item) => {
            allBullets = allBullets.concat(item.accomplishment);
        });
    }

    const allEndWithFullStop = allBullets.every((bullet) =>
        bullet.trim().endsWith(".")
    );
    const noneEndWithFullStop = allBullets.every(
        (bullet) => !bullet.trim().endsWith(".")
    );

    // console.log(allBullets);
    if (allEndWithFullStop || noneEndWithFullStop) {
        return 1;
    }

    return 0;
}

function scorePersonalPronouns(jsonData) {
    const pronouns = personalProCollection;

    const jsonString = JSON.stringify(jsonData, null, 2);

    const lowerCaseJson = jsonString.toLowerCase();

    let pronounCount = 0;
    pronouns.forEach((pronoun) => {
        const regex = new RegExp(`\\b${pronoun.toLowerCase()}\\b`, "g");
        const matches = lowerCaseJson.match(regex);
        if (matches) {
            pronounCount += matches.length;
        }
    });

    if (pronounCount === 0) {
        return { score: 2, message: "No personal pronouns found. Excellent!" };
    } else if (pronounCount <= 3) {
        return {
            score: 1,
            message: `Found ${pronounCount} personal pronoun(s). Consider removing them.`,
        };
    } else {
        return {
            score: 0,
            message: `Found ${pronounCount} personal pronoun(s). This is too many for a professional resume.`,
        };
    }
}


// Utility: Format resume data into readable text
function formatResumeData(resumeData) {
    let text = `Name: ${resumeData.name}\nEmail: ${resumeData.email}\nPhone: ${resumeData.phone}\nLinkedIn: ${resumeData.linkedin}\n\n`;

    text += `Education:\n`;
    resumeData.education.forEach((edu) => {
        text += `- ${edu.degree} in ${edu.major} from ${edu.university} (${edu.graduation})\n`;
    });

    text += `\nExperience:\n`;
    resumeData.experience.forEach((exp) => {
        text += `- ${exp.jobTitle} at ${exp.company} (${exp.duration})\n`;
        exp.description.forEach((desc) => {
            text += `  • ${desc}\n`;
        });
    });

    if (resumeData.leadership?.length) {
        text += `\nLeadership Activities:\n`;
        resumeData.leadership.forEach((lead) => {
            text += `- ${lead.title} at ${lead.organization} (${lead.duration})\n`;
            lead.accomplishment.forEach((acc) => {
                text += `  • ${acc}\n`;
            });
        });
    }

    text += `Education:\n`;
    resumeData.education.forEach((edu) => {
        // Format graduation date
        const gradDate = new Date(edu.graduation);
        const formattedGrad = gradDate > new Date() ? 
            `Expected ${gradDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}` :
            gradDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });

        text += `- ${edu.degree} in ${edu.major} from ${edu.university} (${formattedGrad})\n`;
    });

    return text;
}

function evaluateTechnicalSkills(resumeData) {
    if (!resumeData.skills || !resumeData.skills.technical) {
        return {
            score: 0,
            feedback: ["Technical skills section is missing"]
        };
    }

    const skills = resumeData.skills.technical.split(',').map(s => s.trim());
    let score = 0;
    let feedback = [];

    // Score based on number of skills
    if (skills.length >= 12) {
        score += 2;
    } else if (skills.length >= 8) {
        score += 1;
    }

    // Define skill categories and check coverage
    const categories = {
        programming: ['python', 'java', 'javascript', 'c++', 'sql', 'r'],
        data_analytics: ['tableau', 'power bi', 'excel', 'analytics'],
        project_management: ['agile', 'scrum', 'lean six sigma'],
        tools: ['erp', 'microsoft', 'gantt']
    };

    let categoriesCovered = 0;
    let missingCategories = [];

    for (const [category, keywords] of Object.entries(categories)) {
        if (keywords.some(keyword => 
            skills.some(skill => skill.toLowerCase().includes(keyword.toLowerCase()))
        )) {
            categoriesCovered++;
        } else {
            missingCategories.push(category);
        }
    }

    // Add score for category coverage
    if (categoriesCovered >= 3) {
        score += 1;
    }

    // Perfect score - no feedback needed
    if (score >= 3) {
        return { score: 3, feedback: [] };
    }

    return {
        score,
        feedback: score < 3 ? [
            `Consider adding more diverse skills across these categories: ${missingCategories.join(', ')}`
        ] : []
    };
}

function evaluateEducation(resumeData) {
    let score = 0;
    let feedback = [];
    
    if (resumeData.education && resumeData.education.length > 0) {
        const education = resumeData.education[0]; // Assuming we're evaluating the first education entry
        
        // Check if graduation date exists
        if (education.graduation) {
            // Convert graduation date to a proper format for display
            const gradDate = new Date(education.graduation);
            const today = new Date();
            
            // Expected graduation dates in the future are valid for students
            if (gradDate > today) {
                // This is fine, it's an expected graduation date
                score += 2;
            } else {
                score += 2; // Also give full score for past graduation dates
            }
        } else {
            feedback.push("Missing graduation date.");
            score += 0;
        }

        // Check for required fields and formatting
        const requiredFields = {
            degree: education.degree && education.degree.length > 0,
            major: education.major && education.major.length > 0,
            university: education.university && education.university.length > 0,
            location: education.location && education.location.length > 0
        };

        const missingFields = Object.entries(requiredFields)
            .filter(([_, exists]) => !exists)
            .map(([field]) => field);

        if (missingFields.length === 0) {
            score += 1;
        } else {
            feedback.push(`Missing required fields: ${missingFields.join(', ')}`);
        }

    } else {
        feedback.push("Education section is missing.");
    }

    return { score, feedback };
}

function extractBulletPoints(data) {
    const bulletPoints = [];

    // Extract descriptions from experience
    if (data.experience) {
        data.experience.forEach((exp) => {
            if (exp.description && Array.isArray(exp.description)) {
                bulletPoints.push(...exp.description);
            }
        });
    }

    // Extract accomplishments from leadership
    if (data.leadership) {
        data.leadership.forEach((lead) => {
            if (lead.accomplishment && Array.isArray(lead.accomplishment)) {
                bulletPoints.push(...lead.accomplishment);
            }
        });
    }

    return bulletPoints;
}

// Example: Combine extractBulletPoints with evaluateBulletPoints
function processAndEvaluate(data) {
    const bulletPoints = extractBulletPoints(data);
    return evaluateBulletPoints(bulletPoints);
}

function evaluateBulletPoints(bulletPoints) {
    // Helper functions for evaluations
    const countWords = (text) => text.split(/\s+/).length;
    const isQuantifiable = (text) =>
        /\d+[%]?|(\$[0-9,]+)|(percent|growth|increase|decrease|revenue|savings)/i.test(
            text
        );

    // Find non-compliant lines
    const nonCompliantLines = [];
    for (const point of bulletPoints) {
        const wordCount = countWords(point);
        const hasLengthIssue = wordCount < 15 || wordCount > 30;
        const hasQuantifiableIssue = !isQuantifiable(point);

        if (hasLengthIssue || hasQuantifiableIssue) {
            const issues = [];
            const suggestions = [];

            if (hasLengthIssue) {
                issues.push("Length non-compliance");
                suggestions.push(
                    "Ensure the bullet point has between 15 and 30 words for better readability and effectiveness."
                );
            }

            if (hasQuantifiableIssue) {
                issues.push("Missing quantifiable achievement");
                suggestions.push(
                    "Include measurable results (e.g., percentages, dollar values, growth metrics) to make the point impactful."
                );
            }

            nonCompliantLines.push({
                bullet: point,
                issues,
                suggestion: suggestions.join(" "),
            });
        }
    }

    // Calculate compliance percentages
    const lengthCompliancePercentage =
        ((bulletPoints.length - nonCompliantLines.filter((item) =>
            item.issues.includes("Length non-compliance")
        ).length) / bulletPoints.length) *
        100;

    const quantifiableCompliancePercentage =
        ((bulletPoints.length - nonCompliantLines.filter((item) =>
            item.issues.includes("Missing quantifiable achievement")
        ).length) / bulletPoints.length) *
        100;

    // Scoring based on compliance percentages
    const getScore = (percentage, thresholds) => {
        for (const [threshold, score] of thresholds) {
            if (percentage >= threshold) return score;
        }
        return 0; // Default if none match
    };

    const lengthComplianceScore = getScore(lengthCompliancePercentage, [
        [90, 4],
        [75, 3],
        [50, 2],
    ]);

    const quantifiableAchievementsScore = getScore(
        quantifiableCompliancePercentage,
        [
            [90, 5],
            [75, 4],
            [50, 3],
            [25, 1],
        ]
    );

    return {
        lengthCompliance: lengthComplianceScore,
        feedback: nonCompliantLines.length > 0 ? nonCompliantLines : "All good",
        quantifiableAchievements: quantifiableAchievementsScore,
    };
}


function evaluateLinkedInURL(linkedinURL) {
    // Return 0 points if no LinkedIn URL is provided
    if (!linkedinURL) {
        return {
            score: 0,
            feedback: graderMessage.PERSONAL.ERROR.LINKURLMISSING,
        };
    }

    // Check if the LinkedIn URL contains numeric characters (indicating a standard LinkedIn URL)
    const hasNumerics = /\d/.test(linkedinURL);

    if (hasNumerics) {
        return {
            score: 2,
            feedback: graderMessage.PERSONAL.INFO.LINKFEEDBACK,
        };
    }

    // Customized LinkedIn URL
    return {
        score: 3,
        feedback: "Great job! Your LinkedIn URL is customized and professional.",
    };
}

// Utility function to parse duration strings
// should go in a util file to be made available to other functions

const parseDurationString = (duration) => {
    if (!duration)
        return { startDate: null, endDate: null, isCurrentPosition: false };

    if (Array.isArray(duration)) duration = duration[0];

    const durationStr = String(duration).replace(/[–—]/g, "-").trim();
    const isCurrentPosition = /present|current/i.test(durationStr);

    const parts = durationStr.split("-").map((part) => part.trim());

    const parseMonthYear = (dateStr) => {
        if (!dateStr) return null;

        const match = dateStr.match(/([A-Za-z]+)\s*(\d{4})/i);
        if (!match) return new Date(dateStr);

        const month = match[1];
        const year = match[2];
        const monthNames = {
            jan: 0,
            feb: 1,
            mar: 2,
            apr: 3,
            may: 4,
            jun: 5,
            jul: 6,
            aug: 7,
            sep: 8,
            oct: 9,
            nov: 10,
            dec: 11,
        };
        const monthNum = monthNames[month.toLowerCase().slice(0, 3)];
        return new Date(year, monthNum, 1);
    };

    const startDate = parseMonthYear(parts[0]);
    const endDate =
        !isCurrentPosition && parts[1] ? parseMonthYear(parts[1]) : null;

    return { startDate, endDate, isCurrentPosition };
};

const extractDatesForChronologicalEvaluation = (resumeData) => {
    const dateDictionary = {};

    // Helper to parse dates
    const extractStartAndEndDate = (duration) => {
        if (!duration) return { startDate: null, endDate: null };
        const { startDate, endDate } = parseDurationString(duration);
        return {
            startDate: startDate ? startDate.toISOString().split("T")[0] : null,
            endDate: endDate ? endDate.toISOString().split("T")[0] : null,
        };
    };

    // Helper to check if an object has at least one non-empty field
    const hasValidFields = (obj) =>
        Object.values(obj).some(
            (value) => value && value.trim && value.trim() !== ""
        );

    // Extract dates from education
    if (resumeData.education) {
        const validEducation = resumeData.education
            .filter(hasValidFields)
            .map((edu) => ({
                graduation: edu.graduation,
            }));
        if (validEducation.length) dateDictionary.education = validEducation;
    }

    // Extract start and end dates from experience
    if (resumeData.experience) {
        const validExperience = resumeData.experience
            .filter(hasValidFields)
            .map((exp) => ({
                ...extractStartAndEndDate(exp.duration),
                duration: exp.duration,
            }));
        if (validExperience.length) dateDictionary.experience = validExperience;
    }

    // Extract start and end dates from leadership
    if (resumeData.leadership) {
        const validLeadership = resumeData.leadership
            .filter(hasValidFields)
            .map((lead) => ({
                ...extractStartAndEndDate(lead.duration),
                duration: lead.duration,
            }));
        if (validLeadership.length) dateDictionary.leadership = validLeadership;
    }

    // Extract dates from awards
    if (resumeData.awards) {
        const validAwards = resumeData.awards
            .filter(hasValidFields)
            .map((award) => ({
                date: award.date,
            }));
        if (validAwards.length) dateDictionary.awards = validAwards;
    }

    // Extract dates from certifications
    if (resumeData.certifications) {
        const validCertifications = resumeData.certifications
            .filter(hasValidFields)
            .map((cert) => ({
                year: cert.certification_year,
            }));
        if (validCertifications.length)
            dateDictionary.certifications = validCertifications;
    }

    return dateDictionary;
};

const evaluateReverseChronologyWithFeedback = (dateDictionary) => {
    let totalSections = 0;
    let consistentSections = 0;
    const problematicSections = [];

    // Helper to check reverse chronological order and provide detailed feedback
    const checkReverseChronologicalWithFeedback = (
        dates,
        sectionData,
        section
    ) => {
        let issues = [];
        for (let i = 0; i < dates.length - 1; i++) {
            const current = new Date(dates[i]);
            const next = new Date(dates[i + 1]);

            if (current < next) {
                const currentIndex = i;
                const nextIndex = i + 1;

                // Find the corresponding duration for the mismatched dates
                const currentDuration = sectionData[currentIndex].duration;
                const nextDuration = sectionData[nextIndex].duration;

                issues.push(
                    `"${nextDuration}" should come before "${currentDuration}" because the date range for "${nextDuration}" is earlier than "${currentDuration}".`
                );
            }
        }
        return issues;
    };

    // Evaluate each section
    for (const section in dateDictionary) {
        const sectionData = dateDictionary[section];

        // Extract valid dates for comparison (consider "null" as present date)
        const today = new Date();
        const dates = sectionData
            .map((entry) => {
                const endDate = entry.endDate === null ? today : entry.endDate;
                return (
                    entry.startDate ||
                    entry.graduation ||
                    entry.date ||
                    entry.year ||
                    endDate
                );
            })
            .filter((date) => !isNaN(new Date(date).getTime())) // Include only valid dates
            .map((date) => new Date(date).toISOString()); // Convert to ISO string for consistent comparison

        // Skip sections without enough dates for comparison
        if (dates.length < 2) continue;

        totalSections++;

        let issues = [];

        // Provide feedback based on section type
        if (section === "experience") {
            issues = checkReverseChronologicalWithFeedback(
                dates,
                sectionData,
                section
            );
        } else {
            // General feedback for other sections like awards, certifications, etc.
            for (let i = 0; i < dates.length - 1; i++) {
                const current = new Date(dates[i]);
                const next = new Date(dates[i + 1]);

                if (current < next) {
                    const currentDate =
                        sectionData[i].date ||
                        sectionData[i].year ||
                        sectionData[i].graduation;
                    const nextDate =
                        sectionData[i + 1].date ||
                        sectionData[i + 1].year ||
                        sectionData[i + 1].graduation;

                    issues.push(
                        `"${nextDate}" should come before "${currentDate}" because the date is earlier.`
                    );
                }
            }
        }

        if (issues.length === 0) {
            consistentSections++;
        } else {
            problematicSections.push({ section, issues });
        }
    }

    // Calculate total score (out of 5)
    const totalScore =
        totalSections === 0
            ? 0
            : Math.round((consistentSections / totalSections) * 5);

    // Generate detailed feedback
    const feedback =
        totalScore === 5
            ? "All sections are in perfect reverse chronological order."
            : `Issues found in the following sections:\n${problematicSections
                .map(
                    (entry) => `- ${entry.section}:\n  ${entry.issues.join("\n  ")}`
                )
                .join("\n")}`;

    return {
        totalScore,
        feedback,
    };
};


function evaluatePageNumbers(resumeData) {
    const pageNumbers = resumeData.pageNumber;

    if (pageNumbers <= 2) return 2;
    if (pageNumbers <= 3) return 1;
    return 0;
}